<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="formKaryawan">
      <b-form>
        <b-row>
          <!-- bio -->
          <b-col cols="12">
            <b-form-group label="Nama Gudang" label-for="nama_gudang">
              <validation-provider #default="{ errors }" name="nama_gudang" :rules="{ required: true }">
                <b-form-input id="nama_gudang" v-model="localOptions.nama_gudang" placeholder="Nama Gudang" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ bio -->

          <!-- birth date -->
          <b-col md="6">
            <b-form-group label-for="alamat" label="Alamat">
              <validation-provider #default="{ errors }" name="alamat" :rules="{ required: true }">
                <b-form-textarea id="alamat" v-model="localOptions.alamat" placeholder="Masukkan alamat gudang" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ birth date -->

          <!-- Country -->
          <b-col md="6">
            <b-form-group label-for="keterangan" label="Keterangan"><validation-provider #default="{ errors }"
                name="keterangan" :rules="{ required: true }">
                <b-form-textarea id="keterangan" v-model="localOptions.keterangan" placeholder="Keterangan" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ Country -->

          <!-- website -->
          <!-- <b-col md="6">
          <b-form-group
            label-for="website"
            label="Website"
          >
            <b-form-input
              id="website"
              v-model="localOptions.jenis"
              placeholder="Website address"
            />
          </b-form-group>
        </b-col> -->
          <!--/ website -->

          <!-- phone -->
          <!-- <b-col md="6">
          <b-form-group
            label-for="phone"
            label="Phone"
          >
            <cleave
              id="phone"
              v-model="localOptions.phone"
              class="form-control"
              :raw="false"
              :options="clevePhone"
              placeholder="Phone number"
            />
          </b-form-group>
        </b-col> -->
          <!-- phone -->

          <b-col cols="12">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1"
              @click.prevent="update">
              {{label || 'Simpan Perubahan'}}
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" class="mt-1" variant="outline-secondary"
              @click.prevent="resetForm">
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    flatPickr,
    Cleave,
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      label: '',
      localOptions: JSON.parse(localStorage.getItem('myGudang')),
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
    }
  },
  methods: {
    resetForm() {
      // this.localOptions = JSON.parse(JSON.stringify(this.informationData))
      this.localOptions = JSON.parse(localStorage.getItem('myGudang'))
    },
    update() {
      this.$refs.formKaryawan.validate().then(success => {
        if (success) {
          // const fd = new FormData()
          // for (let key in this.profile) {
          //   if (this.profile[key]) {
          //     if (key == 'id') {
          //       fd.append(`data[0][${key}]`, this.profile['karyawan_id'])
          //     }
          //     else {
          //       fd.append(`data[0][${key}]`, this.profile[key])
          //     }
          //   }
          // }
          const payload = this.localOptions
          this.label = 'Loading...'
          this.$store.dispatch('gudang/save', [payload])
            .then(karyawan => {
              this.label = 'Simpan Perubahan'
              this.displaySuccess({
                message: 'Gudang berhasil diperbaharui'
              })
              this.updateProfile(karyawan)
            })
            .catch(e => {
              this.label = 'Simpan Perubahan'
              this.displayError(e)
              return false
            })
        }
      })
    },
    updateProfile(localOptions) {
      const gud = JSON.parse(localStorage.myGudang)
      if(gud) {
        gud.alamat = localOptions.nama_lengkap
        gud.jenis = localOptions.jenis
        gud.keterangan = localOptions.keterangan
        gud.nama_gudang = localOptions.nama_gudang
        gud.teritori = localOptions.teritori
        gud.karyawan = localOptions.karyawan

        localStorage.setItem('myGudang', JSON.stringify(gud))
        window.location.reload()
      }
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
