<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="formKaryawan">
    <b-form class="">
      <b-row>
        <b-col cols="12" class="mb-3">
          <img v-if="myAvatarProfile" :src="myAvatarProfile" style="width: 60px;" class="d-block rounded mb-2" alt="" />
          <b-button variant="outline-primary" @click.prevent="openFotoModal">
            Ubah Foto Profile
          </b-button>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Nama Lengkap"
            label-for="account-nama_lengkap"
          >
            <b-form-input
              v-model="profile.nama_lengkap"
              placeholder="Nama Lengkap"
              name="nama_lengkap"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              :value="profile.email"
              name="email"
              placeholder="Email"
              disabled
            />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="NIK"
            label-for="account-nik"
          >
          <validation-provider
                      #default="{ errors }"
                      name="nik"
                      :rules="{required: true}"
                    >
            <b-form-input
              v-model="profile.nik"
              placeholder="NIK"
              name="nik"
            />
            <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="NIP"
            label-for="account-nip"
          >
            <b-form-input
              v-model="profile.nip"
              placeholder="NIP"
              name="nip"
            />
          </b-form-group>
        </b-col>
        
        <b-col sm="6">
          <b-form-group
            label="No HP"
            label-for="account-nohp"
          >
            <b-form-input
            disabled
              :value="profile.no_hp"
              name="nohp"
              placeholder="No HP"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Jenis Kelamin"
            label-for="account-jk"
          >
            <b-form-select 
              v-model="profile.jk"
              name="jk"
              placeholder="Jenis Kelamin"
              :options="[{value: 1, text: 'Laki-laki'}, {value: 2, text: 'Perempuan'}]"
            />
          </b-form-group>
        </b-col>
        <!-- Tempat Lhiar -->
        <b-col sm="12" md="6">
          <b-form-group label="Tempat Lahir">
            <b-form-input type="text" v-model="profile.tempat_lahir" placeolder="Ex: Jakarta" />
          </b-form-group>
        </b-col>
        <!-- / -->
        <!-- Tanggal Lahir -->
        <b-col sm="12" md="6">
          <b-form-group label="Tanggal Lahir">
            <b-form-input type="date" v-model="profile.tanggal_lahir" placeolder="Ex: 20/20/2022" />
          </b-form-group>
        </b-col>
        <!-- / -->
        <!-- agama -->
        <b-col sm="12" md="6">
          <b-form-group label="Agama">
            <b-form-select
              v-model="profile.agama"
              name="agama"
              :options="[{value: 1, text: 'Islam'},{value: 2, text: 'Kristen'},{value: 3, text: 'Katolik'},{value: 4, text: 'Hindu'},{value: 5, text: 'Buddha'},{value: 6, text: 'Khonghuchu'}]"
            />
          </b-form-group>
        </b-col>
        <!-- / -->
        <!-- Alamat -->
        <b-col sm="12" md="6">
          <b-form-group label="Alamat">
            <b-form-textarea v-model="profile.alamat" />
          </b-form-group>
        </b-col>
        <!-- / -->
        <b-col sm="12" md="6">
          <b-form-group label="Status Perkawinan">
            <b-form-input v-model="profile.status_perkawinan" />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Kampus Sekolah">
            <b-form-input v-model="profile.kampus_sekolah" />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Pendidikan Terakhir">
            <b-form-input v-model="profile.pendidikan_terakhir" />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Kualifikasi Pendidikan">
            <b-form-input v-model="profile.kualifikasi_pendidikan" />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Masa Kerja">
            <b-form-input v-model="profile.masa_kerja" />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Grade Karyawan">
            <b-form-input v-model="profile.grade_karyawan" />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Jabatan">
            <b-form-input disabled :value="profile.jabatan" />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
                    variant="primary"
                    @click.prevent="submit"
            class="mt-2 mr-1"
                  >
                    {{label || 'Simpan Perubahan'}}
            <!-- Simpan Perubahan -->
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    </validation-observer>
    <foto-modal @submitFoto="submitFoto" />
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import {
  BFormFile, BFormTextarea,BButton, BFormSelect, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import FotoModal from './components/FotoModal.vue'

export default {
  components: {
    FotoModal,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormSelect,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  computed: {
    myAvatarProfile() {
      return this.user.karyawan.url_foto
    },
    statusKaryawan() {
      if(this.profile.status_karyawan == 1) {
        return 'Aktif'
      }
      else {
        return 'Tidak Aktif'
      }
    },
    statusSP() {
      let status = 'Tidak memiliki SP'
      if(this.profile.status_sp == 1) {
        status = 'SP 1'
      }
      else if(this.profile.status_sp == 2) {
        status = 'SP 2'
      }
      else if(this.profile.status_sp == 3) {
        status = 'SP 3'
      }

      return status
    }
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionSP: [{ value: 0, text: "Tidak mempunyai SP" },
        { value: 1, text: "SP 1" },
        { value: 2, text: "SP 2" },
        { value: 3, text: "SP 3" }],
      required, email, confirmed, password,
      profileFile: null,
      label: 'Simpan Perubahan',
      profile: {
        id: null,
        nama_lengkap: null,
        tanggal_lahir: null,
        tempat_lahir: null,
        jk: null,
        nik: null,
        nip: null,
        email: null,
        no_hp: null,
        agama: null,
        alamat: null,
        status_perkawinan: null,
        status_karyawan: null,
        kampus_sekolah: null,
        pendidikan_terakhir: null,
        kualifikasi_pendidikan: "Bagus",
        masa_kerja: "3 tahun",
        grade_karyawan: "A",
        jabatan: null,
        file_foto: null,
        tempat_lahir: "Bandung",
        tanggal_lahir: "2000-02-02",
        status_sp: 2,
        jabatan_id: null
      }
    }
  },
  methods: {
    async submitFoto(fd) {
      for(let key in this.profile) {
        if(this.profile[key]) {
          if(key != 'email' && key != 'no_hp') {
            if(key == 'id') {
              fd.append(`data[0][${key}]`, this.profile['karyawan_id'])
            }
            else {
              fd.append(`data[0][${key}]`, this.profile[key])
            }
          }

          
        }
      }

      try {
        this.loading = true
        const karyawan = await this.$store.dispatch('karyawan/save', fd)
        this.loading = false
        this.$bvModal.hide('foto-modal')
        this.updateProfile(karyawan)
      }
      catch(e) {
        this.loading = false
        this.$bvModal.show('foto-modal')
        this.displayError(e)
        return false
      }
    },
    openFotoModal() {
      this.$bvModal.show('foto-modal')
    },
    updateProfile(karyawan) {
      const user = JSON.parse(localStorage.userData)
      if(user.karyawan) {
        user.karyawan.nama_lengkap = karyawan.nama_lengkap
        user.karyawan.nip = karyawan.nip
        user.karyawan.nik = karyawan.nik
        user.karyawan.jk = karyawan.jk
        user.karyawan.tempat_lahir = karyawan.tempat_lahir
        user.karyawan.tanggal_lahir = karyawan.tanggal_lahir
        user.karyawan.status_sp = karyawan.status_sp
        user.karyawan.status_perkawinan = karyawan.status_perkawinan
        user.karyawan.jabatan_id = karyawan.jabatan ? karyawan.jabatan.id : null
        user.karyawan.jabatan = karyawan.jabatan ? karyawan.jabatan.nama_jabatan : null
        user.karyawan.grade_karyawan = karyawan.grade_karyawan
        user.karyawan.masa_kerja = karyawan.masa_kerja
        user.karyawan.kualifikasi_pendidikan = karyawan.kualifikasi_pendidikan
        user.karyawan.pendidikan_terakhir = karyawan.pendidikan_terakhir
        user.karyawan.kampus_sekolah = karyawan.kampus_sekolah
        user.karyawan.alamat = karyawan.alamat
        user.karyawan.agama = karyawan.agama
        user.karyawan.url_foto = karyawan.url_foto

        localStorage.setItem('userData', JSON.stringify(user))
        window.location.reload()
      }
    },
    initProfile() {
      this.profile.id = this.user.id
      this.profile.karyawan_id = this.user?.karyawan?.id
      this.profile.email = this.user.email
      this.profile.no_hp = this.user.no_hp
      this.profile.nama_lengkap = this.user.karyawan.nama_lengkap
      this.profile.nip = this.user.karyawan.nip
      this.profile.nik = this.user.karyawan.nik
      this.profile.jk = this.user.karyawan.jk
      this.profile.tempat_lahir = this.user.karyawan.tempat_lahir
      this.profile.tanggal_lahir = this.user.karyawan.tanggal_lahir
      this.profile.status_sp = this.user.karyawan.status_sp
      this.profile.status_perkawinan = this.user.karyawan.status_perkawinan
      this.profile.jabatan_id = this.user.karyawan.jabatan ? this.user.karyawan.jabatan.id : null
      this.profile.jabatan = this.user.karyawan.jabatan ? this.user.karyawan.jabatan.nama_jabatan : null
      this.profile.grade_karyawan = this.user.karyawan.grade_karyawan
      this.profile.masa_kerja = this.user.karyawan.masa_kerja
      this.profile.kualifikasi_pendidikan = this.user.karyawan.kualifikasi_pendidikan
      this.profile.pendidikan_terakhir = this.user.karyawan.pendidikan_terakhir
      this.profile.kampus_sekolah = this.user.karyawan.kampus_sekolah
      this.profile.alamat = this.user.karyawan.alamat
      this.profile.agama = this.user.karyawan.agama
    },
    resetForm() {
      this.profile = JSON.parse(localStorage.getItem('userData'))
    },
    submit() {
      
      this.$refs.formKaryawan.validate().then(success => {
        if(success) {
          const fd = new FormData()
          for(let key in this.profile) {
            if(this.profile[key]) {
              if(key == 'id') {
                fd.append(`data[0][${key}]`, this.profile['karyawan_id'])
              }
              else {
                fd.append(`data[0][${key}]`, this.profile[key])
              }
            }
          }
          this.label = 'Loading...'
          this.$store.dispatch('karyawan/save', fd)
          .then(karyawan => {
            this.label = 'Simpan Perubahan'
            this.displaySuccess({
              message: 'Profile berhasil diperbaharui'
            })
            this.updateProfile(karyawan)
          })
          .catch(e => {
            this.label = 'Simpan Perubahan'
            this.displayError(e)
            return false
          })
        }
      })
    },
  },
  created() {
    this.initProfile()
  }
}
</script>
