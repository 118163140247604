<template>
  <b-card>
    <!-- form -->
    <b-form>
      <validation-observer ref="change-password-form">
      <b-row>
        <!-- old password -->
        <b-col md="6">
          <!-- Validation -->
          <validation-provider
            #default="{ errors }"
            name="oldPassword"
            rules="required"
          >
          <b-form-group
            label="Password Lama"
            label-for="account-old-password"
          >
            <b-input-group class="input-group-merge">
                <b-form-input
                  id="account-old-password"
                  v-model="form.oldPassword"
                  name="old-password"
                  :type="passwordFieldTypeOld"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Password Lama"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconOld"
                    class="cursor-pointer"
                    @click="togglePasswordOld"
                  />
                </b-input-group-append>
                <!-- / -->
              </b-input-group>
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <!-- Validation -->
          <validation-provider
            #default="{ errors }"
            name="newPassword"
            rules="required"
          >
          <b-form-group
            label-for="account-new-password"
            label="Password Baru"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                v-model="form.newPassword"
                :type="passwordFieldTypeNew"
                name="newPassword"
                :state="errors.length > 0 ? false : null"
                placeholder="Password Baru"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group
            label-for="account-retype-new-password"
            label="Konfirmasi Password Baru"
          >
            <!-- validation -->
            <validation-provider
              #default="{ errors }"
              name="confNewPassword"
              rules="required|confirmed:newPassword"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="account-retype-new-password"
                  v-model="form.confNewPassword"
                  :type="passwordFieldTypeRetype"
                  :state="errors.length > 0 ? false : null"
                  name="confNewPassword"
                  placeholder="Konfirmasi Password Baru"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconRetype"
                    class="cursor-pointer"
                    @click="togglePasswordRetype"
                  />
                </b-input-group-append>
                
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <!-- / -->
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click.prevent="submit"
          >
            Simpan Password Baru
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mt-1"
          >
            Reset
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </validation-observer>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        oldPassword: null,
        newPassword: null,
        confNewPassword: null,
      },
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    submit() {
      this.$refs['change-password-form'].validate()
      .then(success => {
        if(success) {
          this.$store.dispatch('pengguna/changePassword', {
            old_password: this.form.oldPassword,
            password: this.form.newPassword,
            id: this.user.id
          })
          .then(() => {
            this.displaySuccess({
              message: 'Password berhasil diubah!'
            })
          })
          .catch(e => this.displayError(e))
        }
      })
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    
  },
}
</script>
